<template>
  <b-nav-item-dropdown
    ref="dropdown"
    class="dropdown-user"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0 text-white">
          {{ user.name }}
        </p>
      </div>
      <b-avatar
        :src="user.avatar"
        class="badge-minimal img-thumbnail"
        size="40"
        variant="dark"
      />
    </template>
    <b-dropdown-item @click="$router.push({name:'owner:account-info'})">
      الملف الشخصي
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isSuperAdmin"
      link-class="d-flex align-items-center"
      @click="toggleSuperAdminView"
    >
      <feather-icon
        class="mr-50"
        icon="AirplayIcon"
        size="16"
      />
      <span>{{ onSuperAdminRoutes ? $t('menu.normal-user') : $t('menu.management') }}</span>
    </b-dropdown-item>
    <b-dropdown-text link-class="d-flex align-items-center">
      <dark-toggler />
    </b-dropdown-text>

    <b-dropdown-item
      link-class="d-flex align-items-center text-primary"
      @click="logout"
    >
      <feather-icon
        class="mr-50"
        icon="LogOutIcon"
        size="16"
      />
      <span>{{ $t('auth.logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BAvatar, BDropdownItem, BDropdownText, BNavItemDropdown, VBModal, VBToggle,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileActionsDropdown',
  components: {
    DarkToggler,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownText,
    BAvatar,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isSuperAdmin: 'auth/isAdmin',
      onSuperAdminRoutes: 'navigation/isAdminRoute',
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/LOGOUT')
      window.location.replace(this.$router.resolve({ name: 'login' }).href)
    },
    toggleSuperAdminView() {
      if (this.onSuperAdminRoutes) {
        this.$router.push({ name: 'owner:home' }).catch(() => {
        })
      } else {
        this.$router.push({ name: 'admin:home' })
      }
    },
  },
}
</script>
<style lang="scss">
.dropdown-user .dropdown-menu {
  width: 13rem !important;
}
</style>
